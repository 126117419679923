import React, {Component} from 'react'
import {base} from '../../base';
import appBackgroundImage from '../../styles/images/trivia_background.png';
import sampleScoreboardTopImage from '../../styles/images/top_scoreboard.png';
// import sampleScoreboardBottomImage from '../../styles/images/bottom_scoreboard.png';
// import sampleAppTopImage from '../../styles/images/top_icon.png'

import '../../styles/css/CreatorAwards.css'
import AnswerItemsComponent from "../small_components/AnswerItemsComponent";

class BigScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            frozenVotingState: [],
            answers: [],
            questions: [],
            question: 0,
            currentGame: {},
            isVisible: false,
            rotate: false,
            questionAnswered: false,
            userAnswers: [],
            showPercentages: false
            // triviaGameOn: false,
        };
    }

    componentDidMount() {
        this.currentGameRef = base.bindToState('currentGame', {
          context: this,
          state: 'currentGame',
        })
        this.questionAnsweredRef = base.bindToState('currentGame/answered', {
          context: this,
          state: 'questionAnswered',
        })

        this.questionTextRef = base.bindToState(`currentGame/questions`, {
          context: this,
          state: 'questions'
        });

        this.userAnswersRef = base.bindToState(`userAnswers`, {
            context: this,
            state: 'userAnswers',
            asArray: true
        });
        this.showPercentagesRef = base.bindToState('currentGame/showPercentages', {
            context: this,
            state: 'showPercentages'
        });
    }

    setUpQuestionsWithAnswerCount(userAnswers, answers){
        const answersObject = {};
        if(userAnswers.length > 0) {
            for(let answerIndex in userAnswers) {
                let userAnswer = userAnswers[answerIndex];
                if(answersObject[userAnswer.answerId]){
                    answersObject[userAnswer.answerId]++;
                } else {
                    answersObject[userAnswer.answerId] = 1;
                }
            }
        }
        for(let answerIndex in answers){
            answers[answerIndex].count = 0;
            if(answersObject[answers[answerIndex].id]){
                answers[answerIndex].count = answers[answerIndex].count + answersObject[answers[answerIndex].id];
            }
        }
        return answers;
    }

    handleImageLoaded() {
  setTimeout(() => this.setState({
    isVisible: true
  }), 1);
}

    handleImageErrored() {
  console.log("Image failed to load")
  this.setState({ loading: false });
}

    componentWillUnmount() {
        base.removeBinding(this.questionTextRef);
        base.removeBinding(this.questionAnsweredRef);
        base.removeBinding(this.currentGameRef);
        base.removeBinding(this.userAnswersRef);
        base.removeBinding(this.showPercentagesRef);
    }

    render() {
        const tenantVariables = this.props.variables || {};
        const currentGame = this.state.currentGame;
        const question = currentGame.question || "";
        let questionAnswers = currentGame.questionAnswers || [];
        let questionTopText = tenantVariables.topScoreboardQuestionSize || "44";
        questionTopText = questionTopText + "px";
        if(questionAnswers.length > 0){
            questionAnswers = this.setUpQuestionsWithAnswerCount(this.state.userAnswers, questionAnswers)
        }
        const backgroundImage = tenantVariables.scoreboardBackgroundImage || appBackgroundImage;
        const topOfScoreboardImage = tenantVariables.topOfScoreboardImage || sampleScoreboardTopImage;

        let show_percentages = false;
        if(this.state.showPercentages === "all"){
            show_percentages = true
        } else if (this.state.showPercentages === "parttime" && this.state.questionAnswered) {
            show_percentages = true
        }

        return (
            <div className="flex-container-home" style={{backgroundImage: "url(" + backgroundImage + ")"}}>
                <div className="intro-container-home">
                    <div className="grid-wrapper">
                        <div className="creator-awards-logo-playscreen user"><img src={topOfScoreboardImage} alt="" style={{margin:"0",width:"auto",maxWidth:"none"}}/></div>
                        <div className="card" style={{borderRadius:"20px", margin:0,padding:0}}>
                            <p style={{wordWrap: "break-word", fontWeight:700, color:tenantVariables.progressBarQuestionTextColor, fontSize:questionTopText}}>{question}</p>
                            <AnswerItemsComponent gameover={this.state.questionAnswered} show_percentages={show_percentages} totalCount={this.state.userAnswers.length} question={question} answers={questionAnswers} screenToShow={currentGame.screenToShow} tenantVariables={tenantVariables} onClickAnswer={()=>console.log("click")}/>
                        </div>
                    </div>
                </div>
            </div>
          )
        }
}

export default BigScreen;
