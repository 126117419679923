import React, {Component} from 'react'
import {base} from '../../base';
import appBackgroundImage from '../../styles/images/trivia_background.png';
import '../../styles/css/CreatorAwards.css'
import AnswerItemsComponent from "../small_components/AnswerItemsComponent";

class SideBigScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            frozenVotingState: [],
            answers: [],
            questions: [],
            question: 0,
            currentGame: {},
            isVisible: false,
            rotate: false,
            questionAnswered: false,
            userAnswers: [],
            showPercentages: false
            // triviaGameOn: false,
        };
    }

    componentDidMount() {
        this.currentGameRef = base.bindToState('currentGame', {
          context: this,
          state: 'currentGame',
        });
        this.questionAnsweredRef = base.bindToState('currentGame/answered', {
          context: this,
          state: 'questionAnswered',
        });

        this.questionTextRef = base.bindToState(`currentGame/questions`, {
          context: this,
          state: 'questions'
        });

        this.userAnswersRef = base.bindToState(`userAnswers`, {
            context: this,
            state: 'userAnswers',
            asArray: true
        });
        this.showPercentagesRef = base.bindToState('currentGame/showPercentages', {
            context: this,
            state: 'showPercentages'
        });
    }

    setUpQuestionsWithAnswerCount(userAnswers, answers){
        const answersObject = {};
        if(userAnswers.length > 0) {
            for(let answerIndex in userAnswers) {
                let userAnswer = userAnswers[answerIndex];
                if(answersObject[userAnswer.answerId]){
                    answersObject[userAnswer.answerId]++;
                } else {
                    answersObject[userAnswer.answerId] = 1;
                }
            }
        }
        for(let answerIndex in answers){
            answers[answerIndex].count = 0;
            if(answersObject[answers[answerIndex].id]){
                answers[answerIndex].count = answers[answerIndex].count + answersObject[answers[answerIndex].id];
            }
        }
        return answers;
    }

    handleImageLoaded() {
  setTimeout(() => this.setState({
    isVisible: true
  }), 1);
}

    handleImageErrored() {
  console.log("Image failed to load")
  this.setState({ loading: false });
}

    componentWillUnmount() {
        base.removeBinding(this.questionTextRef);
        base.removeBinding(this.questionAnsweredRef);
        base.removeBinding(this.currentGameRef);
        base.removeBinding(this.userAnswersRef);
        base.removeBinding(this.showPercentagesRef);
    }

    render() {
        const tenantVariables = this.props.variables || {};
        const currentGame = this.state.currentGame;
        const question = currentGame.question || "";
        let questionAnswers = currentGame.questionAnswers || [];
        let questionLeftText = tenantVariables.leftScoreboardQuestionSize || "44";
        questionLeftText = questionLeftText + "px";
        if(questionAnswers.length > 0){
            questionAnswers = this.setUpQuestionsWithAnswerCount(this.state.userAnswers, questionAnswers)
        }
        const backgroundImage = tenantVariables.scoreboardBackgroundImage || appBackgroundImage;
        const leftOfScoreboardImage = tenantVariables.leftOfScoreboardImage || "";
        let show_percentages = false;
        if(this.state.showPercentages === "all"){
            show_percentages = true
        } else if (this.state.showPercentages === "parttime" && this.state.questionAnswered) {
            show_percentages = true
        }
        return (
            <div className="grid-wrapper" style={{backgroundImage: "url(" + backgroundImage + ")"}}>
                <div className="grid-container-outer">
                    <div className="grid-left">
                        <div className="creator-awards-logo"><img src={leftOfScoreboardImage} alt=""/></div>
                    </div>
                    <div>
                        <h1 style={{color:tenantVariables.progressBarQuestionTextColor, marginBottom:"30px", fontSize:questionLeftText, fontWeight:700}}>{question}</h1>
                        <AnswerItemsComponent gameover={this.state.questionAnswered} show_percentages={show_percentages} totalCount={this.state.userAnswers.length} question={question} answers={questionAnswers} screenToShow={currentGame.screenToShow} tenantVariables={tenantVariables} fromScoreboard={true} onClickAnswer={()=>console.log("click")}/>
                    </div>
                </div>
            </div>
          )
        }
}

export default SideBigScreen;
