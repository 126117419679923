import React, {Component} from 'react'
import { Redirect } from 'react-router-dom'
import { unmountComponentAtNode } from "react-dom";
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {base} from '../../base';
import { confirmAlert } from '../utils/react-confirm-alert';
import { validateEmail } from '../utils/HelpfulFunction';
import appBackgroundImage from '../../styles/images/trivia_background.png';
import sampleAppFrontImage from '../../styles/images/front_icon.png';
import sampleAppTopImage from '../../styles/images/top_icon.png';
import AnswerItemsComponent from '../../components/small_components/AnswerItemsComponent';
import { getDistance } from 'geolib';
import 'react-confirm-alert/src/react-confirm-alert.css'
import '../../styles/css/Home.css';
import '../../styles/css/ConfirmAlertCustom.css';
import '../../styles/css/Animations.css';
import '../../styles/css/main.css';
import '../../styles/css/CreatorAwards.css';

class Main_App extends Component {
    constructor(props) {
        super(props);
        this.state = {
          userEmail: localStorage.getItem('userEmail') || sessionStorage.getItem('tempToken') || false,
          alerted: false,
          answerList: [],
          gameActive: false,
          question: '',
          questionAnswered: false,
          userAnswers: [],
          currentGameResponses: [],
          currentGame: {},
          voteSession: false,
          locationChecking: true
        };
        this.logout = this.logout.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    removeReactAlert(){
      document.body.classList.remove('react-confirm-alert-body-element');
      const target = document.getElementById('react-confirm-alert');
      if(target){
        unmountComponentAtNode(target);
        target.parentNode.removeChild(target)
      }
      const svg = document.getElementById('react-confirm-alert-firm-svg');
      if(svg){
        svg.parentNode.removeChild(svg);
        document.body.children[0].classList.remove('react-confirm-alert-blur')
      }
    }

    componentDidMount() {
      if(!navigator.cookieEnabled){
        alert(this.props.stringConstants.NOCOOKIESTEXT);
        return;
      }
      const userEmail = this.state.userEmail;
      let base64EncodedEmail = btoa(userEmail);
      if(userEmail && userEmail.indexOf('@') === -1){
        base64EncodedEmail = userEmail
      }

      this.allResponsesRef = base.bindToState('userAnswers', {
        context: this,
        state: 'userAnswers',
        asArray: true,
      });

      this.gameActiveRef = base.bindToState('currentGame/active', {
        context: this,
        state: 'gameActive',
      });

      this.questionAnsweredRef = base.bindToState('currentGame/answered', {
        context: this,
        state: 'questionAnswered',
      });

      this.questionRef = base.bindToState('currentGame/question', {
        context: this,
        state: 'question',
      });

      this.questionAnswersRef = base.bindToState('currentGame/questionAnswers', {
        context: this,
        state: 'answerList',
        asArray: true
      });

      this.allowMultipleVotesRef = base.bindToState('currentGame/allowMultipleVotes', {
        context: this,
        state: 'allowMultipleVotes'
      });

      this.numberOfVotesRef = base.listenTo('currentGame/numberOfVotes', {
        context: this,
        state: 'numberOfVotes',
        then(numberOfVotes){
          let queries = {orderByChild: 'uid', equalTo: base64EncodedEmail}
          if(typeof numberOfVotes === "number"){
            queries['limitToLast'] = numberOfVotes
          }
          this.currentGameResponsesRef = base.listenTo('userAnswers', {
            context: this,
            asArray: true,
            queries: queries,
            then(currentGameResponses){
              if(currentGameResponses.length === 0 && this.props.variables && this.props.variables.collectDistance && this.props.variables.formattedAddress && this.props.variables.acceptableDistance){
                this.setState({
                  numberOfVotes: numberOfVotes,
                }, () => {
                  this.checkUsersLocation();
                })
              } else {
                this.setState({
                  numberOfVotes: numberOfVotes,
                  currentGameResponses: currentGameResponses,
                  locationChecking: false
                })
              }
            }
          });
        }
      });

      this.sessionLengthRef = base.bindToState('currentGame/sessionLength', {
        context: this,
        state: 'sessionLength'
      });

      this.voteSessionRef = base.bindToState('currentGame/voteSession', {
        context: this,
        state: 'voteSession'
      });

      this.showPercentagesRef = base.bindToState('currentGame/showPercentages', {
        context: this,
        state: 'showPercentages'
      });

      this.currentGameKeyRef = base.listenTo(`currentGame/id`, {
        context: this,
        then(key){
          if(typeof key === "string") {
            this.logUserActivity(key);
          }
        }
      });
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
      window.scrollTo(0,0)
    }

    componentWillUnmount() {
      base.removeBinding(this.currentGameKeyRef);
      base.removeBinding(this.questionRef);
      base.removeBinding(this.questionAnsweredRef);
      base.removeBinding(this.gameActiveRef);
      base.removeBinding(this.allResponsesRef);
      if(this.currentGameResponsesRef){
        base.removeBinding(this.currentGameResponsesRef);
      }
      base.removeBinding(this.allowMultipleVotesRef);
      base.removeBinding(this.numberOfVotesRef);
      base.removeBinding(this.showPercentagesRef);
      base.removeBinding(this.sessionLengthRef);
      base.removeBinding(this.voteSessionRef);
    }

    logout(){
      localStorage.removeItem('verifiedAge');
      localStorage.removeItem('userEmail');
      localStorage.removeItem('birthday');
      localStorage.removeItem('locationPermissions');
      this.setState({
        userEmail:false
      })
    }

    changeMilesToMeters(milesToConvert){
      return milesToConvert*1609.344;
    }

    getLocation(){
      return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
          reject("Geolocation is not supported by your browser. Please change browsers to play!");
        } else {
          const toCheckLatitude = this.props.variables.latitude || 51.525;
          const toCheckLongitude = this.props.variables.longitude || 7.4575;
          navigator.geolocation.getCurrentPosition(
              function(position) {
                resolve(getDistance({latitude: position.coords.latitude, longitude: position.coords.longitude}, {
                  latitude: toCheckLatitude,
                  longitude: toCheckLongitude,
                }))
              },
              (err) => {
                if(err.message === "User denied Geolocation"){
                  reject("Position could not be determined because the browser does not have permission.  Please go to your browsers settings to allow it access to your location");
                } else {
                  console.log(err.message);
                  reject("An unknown error occurred, check your internet connection and try again");
                }
              }
          );
        }
      })
    }

    getLocationPermission(){
      const locationErrorTitle = "Location Error";
      this.getLocation().then(distance_meters => {
        localStorage.setItem('locationPermissions', "true");
        const allowed_distance = this.changeMilesToMeters(this.props.variables.acceptableDistance || 100); //In miles
        if(distance_meters <= allowed_distance){
          this.setState({
            modal:false,
            loading:false,
            locationChecking: false
          });
        } else {
          this.setState({
            modal:false,
            loading:false
          });
          confirmAlert({
            title: locationErrorTitle,
            variables: this.props.variables,
            message: "Too far from game area to participate!",
            buttons: [
              {
                label: 'Retry',
                onClick: () => {
                  this.checkUsersLocation()
                }
              }
            ]
          });
        }
      }, error => {
        console.log(error);
        this.setState({
          modal:false,
          loading:false
        });
        localStorage.setItem('locationPermissions', "false");
        if(typeof error != "string"){
          error = error.message
        }
        confirmAlert({
          title: locationErrorTitle,
          variables: this.props.variables,
          message: error,
          buttons: [
            {
              label: 'Retry',
              onClick: () => {
                this.checkUsersLocation()
              }
            }
          ]
        });
      })
    }

    checkUsersLocation(){
      const variables = this.props.variables || {};
      const locationPermissions = localStorage.getItem('locationPermissions');
      if(!variables.collectDistance) {
        this.setState({locationChecking: false})
      } else if(locationPermissions === "false" || !locationPermissions){
        const locationPermissionsHeader = variables.locationPermissionsHeader || "Location Permissions Required";
        const locationPermissionsBody = variables.locationPermissionsBody || "We need your location in order to play! We use this information to make your experience better";
        confirmAlert({
          title: locationPermissionsHeader,
          variables: variables,
          message: locationPermissionsBody,
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                this.setState({
                  loading: true,
                });
                this.getLocationPermission()
              }
            }
          ],
        })
      } else {
        this.setState({
          loading: true,
        });
        this.getLocationPermission()
      }
    }

    updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

    logUserActivity(currentGame){
      const vm = this;
      let userEmailToBase64 = "";
      if(currentGame && this.state.userEmail.indexOf('@') > -1){
        let gameid = currentGame;
        userEmailToBase64 = btoa(this.state.userEmail);
        base.post('userGameHistory/'+userEmailToBase64+`/${gameid}` , {
          data:gameid,
          then(err){
            if(!err){
              vm.setState({
                alreadyUpdate: gameid,
              })
              console.log("user game logged!")
            }
          }
        })
      }
      var userID = this.state.userEmail;
      if(userID.indexOf('@') > -1){
        userID = btoa(userID);
      }
      if(currentGame){
        base.post('currentGameVisitors/'+userID+"/", {
          data:userID,
          then(err){
            if(!err){
              console.log("currentVisitorLogged!")
            }
          }
        })
      }
    }

    onClickAnswer(answerClicked, position){
      const gridItems = document.getElementsByClassName("grid-item");
      const tenantVariables = this.props.variables || {};
      const allowMultipleVotes = this.state.allowMultipleVotes;
      const numberOfVotesAllowed = this.state.numberOfVotes;
      const sessionLength = this.state.sessionLength;
      const voteSession = this.state.voteSession;
      const responses = this.state.currentGameResponses;
      const stringConstants = this.props.stringConstants || {};
      const element = gridItems[position];
      const answerInfo = {};
      const timestamp = new Date().getTime();
      // document.getElement react-confirm-alert-button-group button
      answerInfo['answerText'] = answerClicked.answerText;
      answerInfo['position'] = position;
      answerInfo['answerId'] = answerClicked.id;
      answerInfo['timeStamp'] = timestamp;
      answerInfo['uid'] = this.state.userEmail;
      if(this.state.userEmail.indexOf('@') > -1){
        answerInfo['uid'] = btoa(this.state.userEmail);
      }

      if(this.state.questionAnswered){
        confirmAlert({
            title: stringConstants.POLLINGOVERHEADER,
            variables: tenantVariables,
            message: stringConstants.NOMOREVOTESATTHISTIMETEXT,
            buttons: [
              {
                label: stringConstants.OKTEXT,
              }
            ]
          });
        return;
      }

      let votesWithinTimeFrame = 0;
      if(voteSession && sessionLength){
        const low_time = timestamp - sessionLength;
        for(const responseIndex in responses){
          if(responses[responseIndex].timeStamp > low_time){
            votesWithinTimeFrame++
          }
        }
      } else {
        votesWithinTimeFrame = responses.length;
      }

      if(!allowMultipleVotes && votesWithinTimeFrame > 0){
        confirmAlert({
            title: stringConstants.NOMOREPICKSTEXT,
            variables: tenantVariables,
            message: stringConstants.ONLYONEPICKPERFAN,
            buttons: [
              {
                label: stringConstants.OKTEXT,
              }
            ]
          });
        return;
      }

      if(allowMultipleVotes && numberOfVotesAllowed && numberOfVotesAllowed !== 0 && votesWithinTimeFrame >= parseInt(numberOfVotesAllowed)){
        confirmAlert({
            title: stringConstants.NOMOREPICKSTEXT,
            variables: tenantVariables,
            message: stringConstants.ONLYCERTAINNUMBEROFVOTESTEXTFIRST +" "+ numberOfVotesAllowed+" "+stringConstants.ONLYCERTAINNUMBEROFVOTESTEXTSECOND,
            buttons: [
              {
                label: stringConstants.OKTEXT,
              }
            ]
          });
        return;
      }

      base.push('userAnswers', {
        data: answerInfo,
        then(err){
          if(!err){
            // gridItems[position].classList.remove("animation", "bounce");
            console.log("Answer Picked!");
            element.classList.add("sheen");
            element.classList.add('start-now');
            setTimeout(function () {
              element.classList.remove("sheen");
              element.classList.remove('start-now')
            }, 1000);
            }
          }
      });
    }

    renderHoldingScreen(){
      const tenantVariables = this.props.variables || {};
      const frontLogoImage = tenantVariables.frontLogoImage || sampleAppFrontImage;
      const secondaryColor = tenantVariables.secondaryColor || "white";
      const primaryColor = tenantVariables.primaryColor || "black";
      const questionTextColor = tenantVariables.questionTextColor || "white";
      return(
        <>
            <div className="hero-text-container">
              <img src={frontLogoImage} className="main-hero-image" alt=""/>
            </div>
            <p style={{display: tenantVariables.playingText ? '' : "none", color:questionTextColor}}>{this.props.stringConstants.NEXTGAMESTARTSTEXT} <br/><span className="emphasizedText" style={{backgroundColor:primaryColor, color:secondaryColor}}>{tenantVariables.playingText}</span></p>
        </>
        )
    }

    validUrl(str) {
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      return !!pattern.test(str);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if(prevState.questionAnswered && !this.state.questionAnswered){
        this.removeReactAlert()
      }
    }

    renderPlayingScreen(){
      const tenantVariables = this.props.variables || {};
      const stringConstants = this.props.stringConstants || {};
      const userAnswers = this.state.userAnswers || [];
      const topLeftLogo = tenantVariables.topLeftImage || sampleAppTopImage;
      let totalTriviaAnswers = 0;
      const answerList = this.state.answerList;
      let show_percentages = false;
      if(this.state.showPercentages === "all"){
        show_percentages = true
      } else if (this.state.showPercentages === "parttime" && this.state.questionAnswered) {
        show_percentages = true
      }

      if(userAnswers.length > 0){
        totalTriviaAnswers = userAnswers.length;
        const tempCount = {};
        for(const answerIndex in userAnswers){
          const answerId = userAnswers[answerIndex].answerId;
          if(tempCount[answerId]){
            tempCount[answerId]['count'] = tempCount[answerId]['count'] + 1;
          } else {
            tempCount[answerId] = {};
            tempCount[answerId]['count'] = 1;
            tempCount[answerId]['answerText'] = userAnswers[answerIndex].answerText;
          }
        }
        for(const add_answer in answerList){
          if(tempCount[answerList[add_answer].id]){
            answerList[add_answer]['count'] = tempCount[answerList[add_answer].id].count
          } else {
            answerList[add_answer]['count'] = 0
          }
        }
      }
      const question = this.state.question;
      return(
            <>
                <div className="creator-awards-logo-playscreen user">
                  <img src={topLeftLogo} alt="" style={{marginBottom:"0"}}/>
                </div>
                <div className="top-text">
                    <p className="top-text-header" style={{fontWeight:700, color: tenantVariables.secondaryColor, display: this.state.questionAnswered ? 'block' : 'none'}}>{stringConstants.FINALRESULTTEXT}</p>
                    <p className="top-text-mobile" style={{wordWrap: "break-word", fontWeight:700, color: tenantVariables.userQuestionColor}}>{question}</p>
                </div>
                <AnswerItemsComponent show_percentages={show_percentages} gameover={this.state.questionAnswered} totalCount={totalTriviaAnswers} question={question} answers={answerList} tenantVariables={tenantVariables} onClickAnswer={(item, i)=>{
                    this.onClickAnswer(item, i)
                  }}/>
                <div className="mobilehide" style={{height:"100px", width:"1px"}}/>
          </>
        )
    }

    render() {
      const tenantVariables = this.props.variables || {};
      const tenantRules = this.state.tenantRules || {};
      const location = window.location.pathname;
      const logOutButtonColor = tenantVariables.logOutButtonColor || "white";
      const turnOffLoginButton = process.env.REACT_APP_FIREBASE_PROJECT_ID === "bearslivepoll";
      const primaryColor = tenantVariables.primaryColor || "black";
      const secondaryColor = tenantVariables.secondaryColor || "white";
      const rulesInAppButtonText = tenantRules.rulesInAppButtonText || "Rules & Regs";
      let rulesShowInAppPopUpHeader = tenantRules.rulesShowInAppPopUpHeader;
      let rulesShowInAppPopUpText = tenantRules.rulesShowInAppPopUpText;
      let rulesPopUpText = tenantRules.rulesPopUpText;
      let rulesPopUpHeader = tenantRules.rulesPopUpHeader;
      const supportHeader = tenantRules.supportHeader || "NEED SUPPORT?";
      const supportText = tenantRules.supportText || "Email customerservice@website.mlb.com with your issue and we'll be in touch!";
      const link = tenantRules.rulesAndRegsLink;
      const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
      const rulesShowInApp = tenantRules.rulesShowInApp || isMlbApp || false;
      if(rulesShowInApp) {
        if(!rulesShowInAppPopUpText && rulesPopUpText){
          rulesShowInAppPopUpText = rulesPopUpText;
        }
        if(!rulesShowInAppPopUpHeader && rulesPopUpHeader){
          rulesShowInAppPopUpHeader = rulesPopUpHeader;
        }
      }
      if(!this.state.userEmail){
        let redirectString = "/login";
        let langague = "";
        let splitWindowLocation = window.location.pathname.split('/') || "";
        if(window.location.pathname.split('/').length > 1){
          langague = "/" + splitWindowLocation[1]
          redirectString += langague
        }
        return (
            <Redirect to={redirectString} />
        )
      } else if(!tenantVariables.doNotCollectEmail && !validateEmail(this.state.userEmail)){
        this.logout();
      } else if(tenantVariables.doNotCollectEmail && validateEmail(this.state.userEmail)){
        this.logout();
      }
      const verifiedAge = this.props.checkForAgeGate(tenantVariables);
      if(!verifiedAge){
        let urlToGo = "/age_gate";
        let langauge = "/";
        let splitWindowLocation = window.location.pathname.split('/') || "";
        if(splitWindowLocation.length > 1){
          langauge += splitWindowLocation[1];
          urlToGo += langauge
        }
        return (
            <Redirect to={urlToGo} />
        )
      }
      let renderMainScreen = this.renderHoldingScreen();
      if(this.state.gameActive && !this.state.locationChecking){
        renderMainScreen = this.renderPlayingScreen();
      }
      let maxWidthAllowed = 300;
      const bodyWidth = Math.max(document.body.offsetWidth, document.body.clientWidth, document.body.scrollWidth);
      if(bodyWidth > 0 && bodyWidth < 300){
        maxWidthAllowed = bodyWidth;
      }
      const backgroundImage = tenantVariables.backgroundImage || appBackgroundImage;
      return(
          <div className="flex-container-home" style={{backgroundImage: "url(" + backgroundImage + ")"}}>
            <div className="flex-content-container-home">
              <div className="intro-container-home" style={{margin: "auto"}}>
                <div className="grid-wrapper">
                  <div className="flex-header-home" style={{marginTop:10}}>
                    <div style={{display: isMlbApp? "block":"none", textAlign: "left"}}>
                      <button className="btn btn-logout" onClick={() => { this.toggleSupport() }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>Support</button>
                    </div>
                    <div style={{visibility: rulesShowInApp? "visible":"hidden", marginLeft: isMlbApp && "auto"}}>
                      <button className="btn btn-logout" onClick={() => { rulesShowInAppPopUpText?this.toggleRules():window.open(link, '_blank') }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>{rulesInAppButtonText}</button>
                    </div>
                    <div style={{display: (isMlbApp || this.props.passedEmail || turnOffLoginButton) && "none"}}>
                      <button className="btn btn-logout" onClick={() => { this.logout() }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>LOG OUT</button>
                    </div>
                  </div>
                  {renderMainScreen}
                </div>
              </div>
            </div>
            <Modal isOpen={this.state.winnerModal} id="myOtherModal">
              <center style={{maxWidth:maxWidthAllowed, marginTop:10, marginBottom: 10}}>
                <span className="unselectable">Hold your finger on the box to save this coupon to your photos<br/>👇👇👇👇👇👇</span>
                <div className="unselectable" style={{height:10, width: "100%"}}/>
                <center id="downloadModalId"/>
              </center>
            </Modal>
            <Modal isOpen={this.state.modalRules} style={{width: '90%'}} id="rulesModal">
              <ModalHeader style={{color: secondaryColor, padding:10}}>
                    <span style={{fontSize:25}}>
                        {rulesShowInAppPopUpHeader}
                    </span>
              </ModalHeader>
              <ModalBody>
                <center className="container-out">
                  <div className="question-box question-form">
                    <p style={{width:"100%",height:200, overflow:"scroll",padding:10, borderBottom:"1px solid black", borderTop: "1px solid black", fontWeight:100, fontSize:14}} dangerouslySetInnerHTML={{ __html:rulesShowInAppPopUpText}}/>
                    <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleRules(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>Dismiss</button>
                  </div>
                </center>
              </ModalBody>
            </Modal>
            <Modal isOpen={this.state.modalSupport} style={{width: '90%'}} id="rulesModal">
              <ModalHeader style={{color: secondaryColor, padding:10}}>
                    <span style={{fontSize:25}}>
                        {supportHeader}
                    </span>
              </ModalHeader>
              <ModalBody>
                <center className="container-out">
                  <div className="question-box question-form">
                    <p style={{width:"100%",overflow:"scroll",fontWeight:100, fontSize:14, marginBottom: 0}} dangerouslySetInnerHTML={{ __html:supportText}}/>
                    <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleSupport(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', fontSize:'1.3 rem'}}>Dismiss</button>
                  </div>
                </center>
              </ModalBody>
            </Modal>
          </div>
      )
    }
}

export default Main_App;
